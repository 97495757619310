<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-10 pt-10" style="margin-top: 0px;">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					<a-card class="p-0 m-0 pb-15" style="">
                        <a-row :gutter="24" class="pt-10">
                            <a-col :span="24" :xs=24 :sm="24" :md="24" :lg="10" :xl="8" class="mb-20">
                                <a-card class="">
                                    <a-row :gutter="24">
                                        <a-col :span="24" :md="24">
                                            <div class="icon">
                                                <img id="classImg" width="100%" height="300px" :src="profileDetails.profile != null ? profileDetails.profile : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                            </div>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24">
                                        <a-col :span="24" :md="24" >
                                            <h5 class="mt-10 pt-10 pl-2 mb-5">{{ formatName(profileDetails.firstName) }} {{ formatName(profileDetails.lastName) }}</h5>
                                        </a-col>

                                        <a-col :span="24" :md="24">
                                            <template v-if="userDetails.role != null && userDetails.role.code == 'teacher'">
                                                <svg v-for="(option, index) in profileDetails.rating != null ? profileDetails.rating : 0" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                                </svg>
                                                <svg v-for="(option, index) in profileDetails.rating != null ? 5 - profileDetails.rating : 5" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                                </svg>
                                            </template>

                                            <a-button id="textBtn" type="text" class="" style="position: absolute; top: -15px; right: 15px;">
                                                <svg class="dark:text-white" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m8 10.9 7-3.2m-7 5.4 7 3.2M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
                                                </svg>
                                                Share
                                            </a-button>
                                        </a-col>

                                        <a-col :span="24" :md="24" class="mt-5">
                                            <span class="">
                                                Last seen: <strong>{{ $Moment(profileDetails.lastSeen).format("DD MMM YYYY") }}</strong>
                                            </span>
                                        </a-col>

                                        <a-col :span="24" :md="24" class="text-center mt-20">
                                            <router-link to="/profile/change-password">
                                                <a-button id="textBtn" type="text" class="pt-0 mt-15 text-primary" style="">
                                                    <!-- <svg class="dark:text-white text-danger" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                                                    </svg> -->
                                                    <a-icon type="edit" theme="outlined" />
                                                    Change Password
                                                </a-button>
                                            </router-link>
                                        </a-col>
                                        <a-col :span="24" :md="24" class="text-center mt-10">
                                            <a-button id="textBtn" type="text" class="pt-0 mt-5 text-danger" style="" @click="deleteAccountConfirmation">
                                                <svg class="dark:text-white text-danger" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                                                </svg>
                                                <span class="text-danger">Delete Account</span>
                                            </a-button>
                                        </a-col>

                                    </a-row>

                                   
                                </a-card>

                            </a-col>
                            <a-col :span="24" :xs=24 :sm="24" :md="24" :lg="14" :xl="16">
                                
                                <a-card class="">

                                    <a-row :gutter="24" class="py-10">
                                        <a-col :span="24" :xs="24" :sm="16" :md="16" :lg="16" :xl="16" class="">
                                            <label style="font-size: 20px;" class=""><strong>Profile Details</strong></label>
                                        </a-col>
                                        
                                        <a-col :span="24" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="text-right">
                                            <router-link to="/profile/edit">
                                                <a-button id="textBtn" type="text" class="pt-0 mt-0" style="">
                                                    <svg class="dark:text-white" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <path stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.4 1.6a2 2 0 0 1 0 2.7l-6 6-3.4.7.7-3.4 6-6a2 2 0 0 1 2.7 0Z"/>
                                                    </svg>
                                                    Edit Profile
                                                </a-button>
                                            </router-link>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-15">
                                        <a-col :span="8" class="mt-0">
                                            <a-card class="text-center" style="background-color: #F5F5FA;">
                                                <h6 class="pb-0 mb-5" style="font-size: 20px;">{{ profileDetails.messages }}</h6>
                                                <p class="mb-0 pb-0 pt-0 mt-0">Messages</p>
                                            </a-card>
                                        </a-col>

                                        <a-col :span="8" class="mt-0">
                                            <a-card class="text-center" style="background-color: #F5F5FA;">
                                                <h6 class="pb-0 mb-5" style="font-size: 20px;">{{ profileDetails.upvotes }}</h6>
                                                <p class="mb-0 pb-0 pt-0 mt-0">Up-votes</p>
                                            </a-card>
                                        </a-col>

                                        <a-col :span="8" class="mt-0">
                                            <a-card class="text-center" style="background-color: #F5F5FA;">
                                                <h6 class="pb-0 mb-5" style="font-size: 20px;">{{ profileDetails.classes.length }}</h6>
                                                <p class="mb-0 pb-0 pt-0 mt-0">Classes</p>
                                            </a-card>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-20" v-if="userDetails.role != null && userDetails.role.code == 'teacher'">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 14px;" class="mt-10"><strong>About me</strong></label><br/>
                                            <p class="py-0 my-0">{{ profileDetails.biography }}</p>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-20" v-if="userDetails.role != null && userDetails.role.code == 'student'">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Education System</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.educationSystems"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.educationGrades.length == 0 ? 'N/A' : ''}}
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-15">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Grades</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.educationGrades"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.educationGrades.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Subjects</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.subjects"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.subjects.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Mode of {{ userDetails.role != null && userDetails.role.code == 'teacher' ? 'teaching' : 'learning'}}</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.teachingModes"
                                                :key="option"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.teachingModes.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15" v-if="userDetails.role != null && userDetails.role.code == 'student'">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Classes</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.myClasses"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.subjects.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15" v-if="userDetails.role != null && userDetails.role.code == 'teacher'">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Days</strong></label><br/>
                                            <a-badge  
                                                v-for="option in profileDetails.days"
                                                :key="option"
                                                style="font-size: 14px !important;" 
                                                :count="`${formatName(option)}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ profileDetails.days.length == 0 ? 'N/A' : ''}}
                                        </a-col>
                                    </a-row>
                                
                                </a-card>
                                
                            </a-col>
                        </a-row>
                    </a-card>
				</a-col>

			</a-row>

            <a-row :gutter="24">
                <a-col :span="24">
                    <a-row :gutter="24" class="px-20">

                        
                        </a-row>
                </a-col>
            </a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


        <!-- FORM MODAL -->
		<a-modal v-model="changePassword.showModal" :footer="null">

			<h4 class="text-center">Change Password</h4>

			<a-form
				:form="passwordForm"
				class="login-form"
				@submit="handleChangePassword"
				:hideRequiredMark="true">

				<a-form-item class="mb-5" label="Old Password" :colon="false">
					<a-input
                        v-decorator="[
                            'password',
                            { rules: [
                                { required: true, message: 'Please enter your old password!' },
                                { min: 8, message: 'Password should have atleast 8 characters!' }
                            ] },
                        ]" 
                        type="password" 
                        placeholder="Old Password" />
				</a-form-item>

                <a-form-item class="mb-5" label="New Password" :colon="false">
					<a-input
                        v-decorator="[
                            'newPassword',
                            { rules: [
                                { required: true, message: 'Please enter your new password!' },
                                { min: 8, message: 'Password should have atleast 8 characters!' }
                            ] },
                        ]" 
                        type="password" 
                        placeholder="New Password" />
				</a-form-item>
				
				<a-form-item class="mb-5" label="Confirm New Password" :colon="false">
					<a-input
                        v-decorator="[
                            'confirmPassword',
                            { rules: [
                                { required: true, message: 'Please re-enter your new password!' },
                                { min: 8, message: 'Password should have atleast 8 characters!' }
                            ] },
                        ]" 
                        type="password" 
                        placeholder="Confirm New Password" />
				</a-form-item>

				<a-form-item class="mt-20">
					<a-button type="primary" :loading="changePassword.loading" id="btn" html-type="submit" class="login-form-button text-white">
						Update
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


        <!-- DELETE ACCOUNT CONFIRMATION -->
        <a-modal v-model="showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-size: 42px;" class="text-center">✋🏽</h6>
					<p style="font-size: 16px; font-weight: 500;">
						You are about to delete your account, once you deleted you will not be able to recover this account
					</p>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20">
				<a-col :span="24" class="text-center">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="showModal == false">
						Cancel
					</a-button>
					&nbsp;&nbsp;
					<a-button id="textBtn" type="text" class="" style="">
						<span class="text-danger">Delete Account</span>
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner';
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                activeTab: 1,
				classes: [],
                userDetails: {},
                profileDetails: {
                    uuid: null,
                    firstName: null,
                    lastName: null,
                    gender: null,
                    dateOfBirth: null,
                    profile: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    emailAddress: null,
                    countryCode: null,
                    biography: null,
                    messages: 0,
                    upvotes: 0,
                    lastSeen: null,
                    classes: [],
                    days: [],
                    myClasses: [],
                    teachingModes: [],
                    rating: null,
                    viewsCount: null,
                    createdAt: null,
                    updatedAt: null,
                },

                changePassword: {
                    loading: false,
                    showModal: false,
                },

				currentPage: 1,

                showModal: false,

      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Profile', url: '/profile', isActive: true },
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
        beforeCreate() {
			this.passwordForm = this.$form.createForm(this, { name: 'password_details' });
		},
		created() {
			this.getTutorProfile()
		},
		methods: {

            toBookingPage() {
                this.$router.push(`/tutors/view/${this.profileDetails.uuid}/booking`)
            },

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			async getTutorProfile() {

                this.loadingData = true;

				let userInfo = await localStorage.getItem("user_details")

                this.userDetails = userInfo != null ? JSON.parse(userInfo) : {};

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/profiles?uuid=${this.userDetails.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.profileDetails.uuid = response.data.data.uuid;
                        this.profileDetails.firstName = response.data.data.firstName;
                        this.profileDetails.lastName = response.data.data.lastName;
                        this.profileDetails.gender = response.data.data.gender;
                        this.profileDetails.dateOfBirth = response.data.data.dateOfBirth;
                        this.profileDetails.profile = response.data.data.profile;
                        this.profileDetails.educationSystems = response.data.data.educationSystems;
                        this.profileDetails.educationLevels = response.data.data.educationLevels;
                        this.profileDetails.educationGrades = response.data.data.educationGrades;
                        this.profileDetails.subjects = response.data.data.subjects;
                        this.profileDetails.emailAddress = response.data.data.emailAddress;
                        this.profileDetails.countryCode = response.data.data.countryCode;
                        this.profileDetails.biography = response.data.data.biography;
                        this.profileDetails.upvotes = response.data.data.upvotes ?? 0;
                        this.profileDetails.messages = response.data.data.messages;
                        this.profileDetails.days = response.data.data.days;
                        this.profileDetails.teachingModes = response.data.data.teachingModes;
                        this.profileDetails.lastSeen = response.data.data.lastSeen;
                        this.profileDetails.rating = response.data.data.rating;
                        this.profileDetails.viewsCount = response.data.data.viewsCount;
                        this.profileDetails.createdAt = response.data.data.createdAt;
                        this.profileDetails.updatedAt = response.data.data.updatedAt;
                        this.profileDetails.myClasses = response.data.data.classes;
                        this.profileDetails.classes = await this.filterClasses(response.data.data.classes, response.data.data.uuid);
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            filterClasses(classes, uuid) {
                return classes != null && classes.length > 0 ? classes.filter(clas => clas.creator != null && clas.creator.uuid == uuid) : []
            },


            deleteAccountConfirmation() {
                this.showModal = true;
            },


            handleChangePassword(e) {
				e.preventDefault();

				this.passwordForm.validateFields((err, values) => {
					if ( !err ) {

                        if(values.newPassword == values.confirmPassword) {

                            this.changePassword.loading = true;

                            let payload = values;

                            let url = `${this.$BACKEND_URL}/auths/change-password`;

                            this.$AXIOS.post(url, payload).then(async(response) => {
                                if (response.status >= 200 && response.status < 210) {

                                    this.notify('Password has been changed successfully', 'success');

                                    await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)
                                    await localStorage.setItem("user_token", null)

                                    this.$router.push(`/sign-in`);
                                    
                                }
                                this.changePassword.loading = false;

                            }).catch((err) => {
                                
                                this.changePassword.loading = true;
                                
                                this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                            });
                            
                        }else{
                            this.notify("New password and confirm password do not match", 'error');
                        }

					}
				});
			},


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },


		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>


<!-- {
    